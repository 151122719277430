import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../layouts/Layout";
import APIServices from "../../../services/graphql/query";
import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST,
} from "../../../services/graphql/config";
import QRCode from "react-qr-code";
import axios from "axios";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "65251d89ee13e90056ad6d17" : "65533b84f2cc1100605dde3e"; //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";

const Welcome = () => {
  const navigate = useNavigate();
  const [qrCode, setQrCode] = useState("");
  const [status, setStatus] = useState(0);
  const [qrcodeShortLink, setQrShortLink] = useState("");
  const [deeplinkContent, SetDeeplinkContent] = useState("");

  const goDeepLink = (mode) => {
    console.log("goDeepLink");
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me") {
      data =
        IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }

    if (deeplinkContent) window.open(data, "_blank");
  };

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log("init...");
    APIServices.generateQRSession((response) => {
      console.log("response", response);
      console.log("response.status", response.status);
      if (response.status === 1) {
        SetDeeplinkContent(
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":")
        );
        let data =
          IDENTIFI_DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          IDENTIFI_DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };
    APIServices.getQRRequestItems(params, (response) => {
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );
            let result = JSON.parse(data).qrRes;
            console.log("result", result);
            //sessionId = ""
            if (result) {
              const res = result;
              const age = res.split("Over 18 years:")[1];
              // const fav_song = res.split("||")[1];
              // const fav_drink = res.split("||")[2];
              console.log("res", res);
              navigate(process.env.PUBLIC_URL + "/nightclub/result", {
                state: { age },
              });
            } else {
              setStatus(-1);
              //navigate(process.env.PUBLIC_URL + "/building/failed", {deviceId: null});
            }
          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div
          className="night-club-area"
          style={{
            backgroundImage: "url('/assets/img/club/background.png')",
            position: "absolute",
          }}
        >
          <div className="content">
            <img alt="" src="/assets/img/club/welcome.png" />
            <div className="description">
              Please scan the QR code to prove you are over 21
            </div>
            <div className="qrcode-section">
              <div
                style={{
                  width: "260px",
                  height: "260px",
                  margin: "auto",
                  borderWidth: "4px",
                  borderColor: "#fff",
                  cursor: "pointer",
                }}
                onClick={goDeepLink}
              >
                {qrCode && (
                  <QRCode size={256} value={qrCode} viewBox={`0 0 256 256`} />
                )}
              </div>
            </div>
            <div className="bottom-logo">
              <img alt="" width={26} src="/assets/img/hotel/logo.png" />
              <div className="logo-text">Powered by ShareRing</div>
            </div>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};
export default Welcome;
